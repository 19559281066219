import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
	{
		path: '/',
		name: 'layout',
		component: () => import('../views/layout'),
		redirect: '/home',
		children: [
			{
				path: '/home',
				name: 'home',
				component: () => import('../views/home/index'),
			},

			{
				path: '/center',
				name: 'center',
				component: () => import('../views/center/index_new'),
			},
			{
				path: '/found',
				name: 'found',
				component: () => import('../views/found'),
			},
			{
				path: '/myfound',
				name: 'myfound',
				component: () => import('../views/myfound'),
			},
			{
				path: '/foundpool',
				name: 'foundpool',
				component: () => import('../views/foundpool'),
			},
			{
				path: '/dig',
				name: 'dig',
				component: () => import('../views/dig'),
			},
			{
				path: '/node',
				name: 'node',
				component: () => import('../views/node'),
			},
			{
				path: '/contactUs',
				name: 'contactUs',
				component: () => import('../views/contactUs'),
			},

			//公告
			{
				path: '/notice',
				name: 'notice',
				component: () => import('../views/notice'),
			},
			{
				path: '/notice-detail',
				name: 'noticeDetail',
				component: () => import('../views/notice/detail'),
			},
			{
				path: '/asset',
				name: 'asset',
				component: () => import('../views/asset/index.vue'),
			},
			{
				path: '/assetList',
				name: 'assetList',
				component: () => import('../views/asset/list.vue'),
			},
			{
				path: '/topup',
				name: 'topup',
				component: () => import('../views/asset/topup.vue'),
			},
			{
				path: '/withdraw',
				name: 'withdraw',
				component: () => import('../views/asset/withdraw.vue'),
			},
			{
				path: '/withdrawsuo',
				name: 'withdrawsuo',
				component: () => import('../views/asset/withdrawsuo.vue'),
			},
			{
				path: '/records',
				name: 'records',
				component: () => import('../views/records/index.vue'),
			},
			{
				path: '/aboutus',
				name: 'aboutus',
				component: () => import('../views/aboutUs/index'),
			},
			{
				path: '/award',
				name: 'award',
				component: () => import('../views/award/award.vue'),
			},
		],
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;

module.exports = {
  Connect: "连接钱包",
  Notopenyet: "待开放",
  main: {
    t1: "暂无数据",
    t2: "上一页",
    t3: "下一页",
    t4: "请输入充值数量",
    t5: "请输入提取数量",
    t6: "取消",
    t7: "确认",
    t8: "上级地址",
    t9: "直推人数",
    t10: "直推有效人数",
    t11: "团队净资产",
    t12: "团队人数",
    t13: "团队有效人数",
    t14: "团队业绩",
    t15: "身份标识",
    t16: "业绩",
    t17: "等级",
    t18: "状态",
    t19: "已加速",
    t20: "天",
    t21: "连续收益",
    t22: "收益次数",
    t23: "机枪池",
    t24: "收益增长",
    t25: "转入",
    t26: "转出",
    t27: "时间",
    t28: "类型",
    t29: "数量",
    t30: "嵌入",
    t31: "卸取",
    t32: "加速",
    t33: "请输入嵌入数量",
    t34: "请输入提取数量",
    t35: "静态增长",
    t36: "昨日",
    t37: "动态增长",
    t38: "昨日",
    t39: "时间",
    t40: "物品",
    t41: "数量",
    t42: "返",
    t43: "回",
    t44: "首页",
    t45: "机枪池",
    t46: "收益",
    t47: "资产",
    t48: "个人中心",
    t49: "公告",
    t50: "RWA资产",
    t51: "社交",
    t52: "挖矿",
    t53: "节点",
    t54: "",
    t55: "",
    t56: "",
    t57: "",
    t58: "",
    t59: "",
    t60: "",
    t61: "",
    t62: "",
    t63: "",
    t64: "",
    t65: "",
    t66: "",
    t67: "",
    t68: "",
    t69: "",
    t70: "",
    t71: "",
    t72: "",
    t73: "",
    t74: "",
    t75: "",
    t76: "",
    t77: "",
    t78: "",
    t79: "",
    t80: "",
    t81: "",
    t82: "",
    t83: "",
    t84: "",
    t85: "",
    t86: "",
    t87: "",
    t88: "",
    t89: "",
    t90: "",
    t91: "",
    t92: "",
    t93: "",
    t94: "",
    t95: "",
    t96: "",
    t97: "",
    t98: "",
    t99: "",
    t100: "",
    t101: "",
    t102: "",
    t103: "",
    t104: "",
    t105: "",
    t106: "",
    t107: "",
    t108: "",
    t109: "",
    t110: "",
    t111: "",
    t112: "",
    t113: "",
    t114: "",
    t115: "",
    t116: "",
    t117: "",
    t118: "",
    t119: "",
    t120: "",
    t121: "",
    t122: "",
    t123: "",
    t124: "",
    t125: "",
    t126: "",
    t127: "",
    t128: "",
  },
  home: {
    t1: "",
    t2: "热门币种",
    t3: "币种",
    t4: "价格",
    t5: "涨跌幅",
    t6: "发现",
    t7: "首页",
    t8: "基金",
    t9: "我的基金",
    t10: "共创基金池",
    t11: "个人中心",
    t12: "资金",
    t13: "公告",
    t14: "请妥善保管好自己资产",
    t15: "奖励",
    t16: "充值",
    t17: "奖励提取",
    t18: "充值提取",
    t19: "记录",
    t20: "日期",
    t21: "类型",
    t22: "数量",
    t23: "静态",
    t24: "动态",
    t25: "动态",
    t26: "平级",
    t27: "极差",
    t28: "直推",
    t29: "间接",
    t30: "三代",
    t31: "提币",
    t32: "充值",
    t33: "提币退回",
    t34: "购买基金",
    t35: "充值提取",
    t36: "充值",
    t37: "冻结",
    t38: "复制",
    t39: "提币",
    t40: "数量",
    t41: "可用余额：",
    t42: "手续费：",
    t43: "时间：",
    t44: "提现地址：",
    t45: "提现数量：",
    t46: "提现手续费：",
    t47: "提现状态：",
    t48: "记录",
    t49: "审核中",
    t50: "已确认",
    t51: "已拒绝",
    t52: "充值余额",
    t53: "日期",
    t54: "类型",
    t55: "币种",
    t56: "数量",
    t57: "静态收益",
    t58: "团队收益",
    t59: "VIP收益",
    t60: "累计收益：",
    t61: "我的BSC钱包地址",
    t62: "推荐人地址",
    t63: "推荐",
    t64: "我的团队",
    t65: "邀请",
    t66: "地址",
    t67: "层级",
    t68: "状态",
    t69: "有效",
    t70: "无效",
    t71: "提交工单",
    t72: "地址",
    t73: "邮箱",
    t74: "提交",
    t75: "",
    t76: "Favorate  Capital总部位于",
    t77: "新加坡，是一家金融科技",
    t78: "公司。成立于2017年，旨在为",
    t79: "金融科技创新提供平台。",
    t80: "我们的使命是推动金融领",
    t81: "域的创新和变革 ，以更好地满",
    t82: "足客户需求 ，促进全球经济发展。",
    t83: "基金",
    t84: "投资金额",
    t85: "赠送2.6倍额度",
    t86: "赠送3.0倍额度",
    t87: "赠送3.2倍额度",
    t88: "投入",
    t89: "终止理财",
    t90: "动静态加速赠送额度出局",
    t91: "$100的倍数起参与AI理财分红",
    t92: "我的基金值：",
    t93: "全网基金值：",
    t94: "基金池地址",
    t95: "地址",
    t96: "个人剩余基金值/当前基金池总值",
    t97: "基金池总资产=个人所得资产",
    t98: "备注：1、用户参与理财金额起，自动获得基金值，$1000送一个基金值，本金释放完成基金值归零，完美解决金字塔的问题。",
    t99: "我的基金值",
    t100: "待产出（usdt）",
    t101: "已产出（usdt）",
    t102: "基金值",
    t103: "已产出记录",
    t104: "订单号：",
    t105: "收益记录",
    t106: "创建时间：",
    t107: "已结束",
    t108: "状态：",
    t109: "收益：",
    t110: "暂无数据",
    t111: "生息中",
    t112: "公告",
    t113: "业绩",
    t114: "我的业绩",
    t115: "绑定邀请地址",
    t116: "取消",
    t117: "提交",
    t118: "网络错误",
    t119: "签名信息错误",
    t120: "成功",
    t121: "我的身份",
    t122: "",
    t123: "",
    t124: "",
    t125: "",
    t126: "",
    t127: "",
    t128: "",
    t129: "",
    t130: "",
    t131: "",
    t132: "",
    t133: "",
    t134: "",
    t135: "",
    t136: "",
    t137: "",
    t138: "",
    t139: "",
    t140: "",
    t141: "",
    t142: "",
    t143: "",
    t144: "",
    t145: "",
    t146: "",
    t147: "",
    t148: "",
    t149: "",
    t150: "",
    t151: "",
    t152: "",
    t153: "",
    t154: "",
    t155: "",
    t156: "",
    t157: "",
    t158: "",
    t159: "",
    t160: "",
    t161: "",
    t162: "",
    t163: "",
    t164: "",
    t165: "",
    t166: "",
    t167: "",
    t168: "",
    t169: "",
    t170: "",
    t171: "",
    t172: "",
    t173: "",
    t174: "",
    t175: "",
    t176: "",
    t177: "",
    t178: "",
    t179: "",
    t180: "",
    t181: "",
    t182: "",
    t183: "",
    t184: "",
    t185: "",
    t186: "",
    t187: "",
    t188: "",
    t189: "",
    t190: "",
    t191: "",
    t192: "",
    t193: "",
    t194: "",
    t195: "",
    t196: "",
    t197: "",
    t198: "",
    t199: "",
    t200: "",
    t201: "",
    t202: "",
    t203: "",
    t204: "",
    t205: "",
    t206: "",
    t207: "",
    t208: "",
    t209: "",
    t210: "",
    t211: "",

  },
}
module.exports = {
  Connect: " Connect wallet ",
  Notopenyet: " Notopen ",
  main: {
    t1: "No data yet ",
    t2: "Back",
    t3: "Next",
    t4: "Please enter the amount of recharge ",
    t5: "Please enter the extraction quantity ",
    t6: "Cancel",
    t7: "Confirm",
    t8: "Parent Address ",
    t9: "Direct push number ",
    t10: "Direct push valid number ",
    t11: "Team Net Assets ",
    t12: "Team size ",
    t13: "Team size ",
    t14: "Team Performance ",
    t15: "Identification ",
    t16: "Performance ",
    t17: "Level ",
    t18: "Status ",
    t19: "Add ",
    t20: "Day ",
    t21: "Continuous",
    t22: "Number",
    t23: "Gun Pool",
    t24: "Revenue",
    t25: "In",
    t26: "Out",
    t27: "Time ",
    t28: "Type ",
    t29: "Quantity ",
    t30: "Embedded ",
    t31: "Unload ",
    t32: "Add ",
    t33: "Please enter the number of embeddings ",
    t34: "Please enter the extraction quantity ",
    t35: "Static Growth ",
    t36: "Yesterday ",
    t37: "Dynamic Growth ",
    t38: "Yesterday ",
    t39: "Time ",
    t40: "Item ",
    t41: "Quantity ",
    t42: "Return ",
    t43: "Back",
    t44: "Home",
    t45: "Pool",
    t46: "Earnings",
    t47: "Assets",
    t48: "Center",
    t49: "Announcement",
    t50: "RWA-Assets",
    t51: "Club",
    t52: "Dig",
    t53: "Node",
  },
  home: {
    t1: "Kimbit Quant is a smart trading platform launched by Favorate Capital. The platform combines artificial intelligence and machine learning to help traders make accurate trading decisions. ",
    t2: "Popular Currency ",
    t3: "Currency ",
    t4: "Price ",
    t5: "Rise and fall ",
    t6: "Discovery ",
    t7: "Home Page ",
    t8: "Fund ",
    t9: "My Fund ",
    t10: "Create Fund Pool ",
    t11: "Personal Center ",
    t12: "Funds ",
    t13: "Announcement ",
    t14: "Please take good care of your assets ",
    t15: "Reward ",
    t16: "Recharge ",
    t17: "Rd draw ",
    t18: "Re draw ",
    t19: "Record ",
    t20: "Date ",
    t21: 'Type',
    t22: "Quantity ",
    t23: "Static ",
    t24: "Dynamic ",
    t25: "Dynamic ",
    t26: "Level ",
    t27: "Very poor ",
    t28: "Direct push ",
    t29: "Indirect ",
    t30: "Three Generations ",
    t31: "Withdrawal ",
    t32: "Recharge ",
    t33: "Withdrawal",
    t34: "Purchase Funds ",
    t35: "Recharge",
    t36: "Locked  ",
    t37: "Freeze ",
    t38: "Copy ",
    t39: "Withdrawal ",
    t40: "Quantity ",
    t41: "Available Balance: ",
    t42: "Handling Fee: ",
    t43: "Time: ",
    t44: "Withdrawal Address: ",
    t45: "Withdrawal quantity: ",
    t46: "Withdrawal Fee: ",
    t47: "Withdrawal Status: ",
    t48: "Record ",
    t49: "Under review ",
    t50: "Confirmed ",
    t51: "Rejected ",
    t52: "Top up Balance ",
    t53: "Date ",
    t54: 'Type',
    t55: "Currency ",
    t56: "Quantity ",
    t57: "Static ",
    t58: "Team ",
    t59: "VIP ",
    t60: "Cumulative: ",
    t61: "My BSC wallet address ",
    t62: "Reference Address ",
    t63: "Recommended ",
    t64: "My Team ",
    t65: "Invitation ",
    t66: "Address ",
    t67: "Hierarchy ",
    t68: "Status ",
    t69: "Effective ",
    t70: "Invalid ",
    t71: "Submit work order ",
    t72: "Address ",
    t73: "Mailbox ",
    t74: "Submit ",
    t75: "",
    t76: "Favorate Capital headquarters is located ",
    t77: "Singapore is a fintech ",
    t78: "The Company. Established in 2017 with the aim of ",
    t79: "Fintech innovation provides a platform.",
    t80: "Our mission is to advance the financial sector ",
    t81: "Domain innovation and change to better fill ",
    t82: "Meet customer needs and promote global economic development.",
    t83: "Fund ",
    t84: "Investment Amount ",
    t85: "2.6 times allowance ",
    t86: "Free 3.0 times credit ",
    t87: "3.2 times the amount ",
    t88: "Input ",
    t89: "Terminate Financial Management ",
    t90: "Dynamic and Static acceleration gift out ",
    t91: "Participate in AI financial dividends starting at multiples of $100 ",
    t92: "My Fund value: ",
    t93: "Net Fund Value: ",
    t94: "Fund Pool Address ",
    t95: "Address ",
    t96: "Individual Remaining Fund Value/Current Fund Pool Value ",
    t97: "Total Assets of the fund pool = personal income Assets ",
    t98: "Note: 1, users participate in the amount of financial management, automatically get the fund value, $1000 send a fund value, the principal release completes the fund value to zero, perfect solution to the pyramid problem.",
    t99: "My Fund Value ",
    t100: "Pending Output (usdt) ",
    t101: "Produced (usdt) ",
    t102: "Fund Value ",
    t103: "Records produced ",
    t104: "Order Number: ",
    t105: "Earnings Record ",
    t106: "Creation time: ",
    t107: "Finished ",
    t108: "Status: ",
    t109: "Revenue: ",
    t110: "No data available ",
    t111: "Breathing ",
    t112: "Announcement ",
    t113: "Grade",
    t114: "My grade",
    t115: "Bind Address",
    t116: "Cancel",
    t117: "Confirm",
    t118: "network error",
    t119: "Pass Info Error",
    t120: "succes",
    t121: "My Node",
    t122: "",
    t123: "",
    t124: "",
    t125: "",
    t126: "",
    t127: "",
    t128: "",
    t129: "",
    t130: "",
    t131: "",
    t132: "",
    t133: "",
    t134: "",
    t135: "",
    t136: "",
    t137: "",
    t138: "",
    t139: "",
    t140: "",
    t141: "",
    t142: "",
    t143: "",
    t144: "",
    t145: "",
    t146: "",
    t147: "",
    t148: "",
    t149: "",
    t150: "",
    t151: "",
    t152: "",
    t153: "",
    t154: "",
    t155: "",
    t156: "",
    t157: "",
    t158: "",
    t159: "",
    t160: "",
    t161: "",
    t162: "",
    t163: "",
    t164: "",
    t165: "",
    t166: "",
    t167: "",
    t168: "",
    t169: "",
    t170: "",
    t171: "",
    t172: "",
    t173: "",
    t174: "",
    t175: "",
    t176: "",
    t177: "",
    t178: "",
    t179: "",
    t180: "",
    t181: "",
    t182: "",
    t183: "",
    t184: "",
    t185: "",
    t186: "",
    t187: "",
    t188: "",
    t189: "",
    t190: "",
    t191: "",
    t192: "",
    t193: "",
    t194: "",
    t195: "",
    t196: "",
    t197: "",
    t198: "",
    t199: "",
    t200: "",
    t201: "",
    t202: "",
    t203: "",
    t204: "",
    t205: "",
    t206: "",
    t207: "",
    t208: "",
    t209: "",
    t210: "",
    t211: "",

  },
}
import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale:localStorage['SUN_lang']?localStorage['SUN_lang']:'ch',
  legacy:false,
  globalInjection: true,
  messages: {
    ch: require('./ch.js'),
    en: require('./en.js'),
  }
})
export default i18n;